////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

export const PROJECT_APP = "ECINNOVA"
// export const PROJECT_APP = "INSERTATOLEDO"
// export const PROJECT_APP = "CARITASTOLEDO"

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

export const ECINNOVA = "ECINNOVA"
export const INSERTATOLEDO = "INSERTATOLEDO"
export const CARITASTOLEDO = "CARITASTOLEDO"

export let firebaseConfig = {
    apiKey: null,
    authDomain: null,
    projectId: null,
    storageBucket: null,
    messagingSenderId: null,
    appId: null,
    measurementId: null,
};

export let empresa = null

export let colorProject = null

export let contraseñaPorDefecto = null


if (PROJECT_APP === ECINNOVA) {
    firebaseConfig.apiKey = process.env.REACT_APP_APIKEY_ECINNOVA
    firebaseConfig.authDomain = process.env.REACT_APP_AUTHDOMAIN_ECINNOVA
    firebaseConfig.projectId = process.env.REACT_APP_PROJECTID_ECINNOVA
    firebaseConfig.storageBucket = process.env.REACT_APP_STORAGEBUCKET_ECINNOVA
    firebaseConfig.messagingSenderId = process.env.REACT_APP_MESSAGINGSENDERID_ECINNOVA
    firebaseConfig.appId = process.env.REACT_APP_APPID_ECINNOVA
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENTID_ECINNOVA

    empresa = "ecinnova"

    colorProject = "#662483"

    contraseñaPorDefecto = process.env.REACT_APP_CONTRASENAPORDEFECTO_ECINNOVA
}
else if (PROJECT_APP === INSERTATOLEDO) {
    firebaseConfig.apiKey = process.env.REACT_APP_APIKEY_INSERTATOLEDO
    firebaseConfig.authDomain = process.env.REACT_APP_AUTHDOMAIN_INSERTATOLEDO
    firebaseConfig.projectId = process.env.REACT_APP_PROJECTID_INSERTATOLEDO
    firebaseConfig.storageBucket = process.env.REACT_APP_STORAGEBUCKET_INSERTATOLEDO
    firebaseConfig.messagingSenderId = process.env.REACT_APP_MESSAGINGSENDERID_INSERTATOLEDO
    firebaseConfig.appId = process.env.REACT_APP_APPID_INSERTATOLEDO
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENTID_INSERTATOLEDO

    empresa = "insertatoledo"

    colorProject = "#cd222d"

    contraseñaPorDefecto = process.env.REACT_APP_CONTRASENAPORDEFECTO_INSERTATOLEDO
}
else if (PROJECT_APP === CARITASTOLEDO) {
    firebaseConfig.apiKey = process.env.REACT_APP_APIKEY_CARITASTOLEDO
    firebaseConfig.authDomain = process.env.REACT_APP_AUTHDOMAIN_CARITASTOLEDO
    firebaseConfig.projectId = process.env.REACT_APP_PROJECTID_CARITASTOLEDO
    firebaseConfig.storageBucket = process.env.REACT_APP_STORAGEBUCKET_CARITASTOLEDO
    firebaseConfig.messagingSenderId = process.env.REACT_APP_MESSAGINGSENDERID_CARITASTOLEDO
    firebaseConfig.appId = process.env.REACT_APP_APPID_CARITASTOLEDO
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENTID_CARITASTOLEDO

    empresa = "caritas.toledo"

    colorProject = "#cd222d"

    contraseñaPorDefecto = process.env.REACT_APP_CONTRASENAPORDEFECTO_CARITASTOLEDO
}